import store from '@/store';

const FM_STORE_KEY = 'flash/message';
const FM_SUCCESS = 'success';
const FM_ERROR = 'error';

const FlashUtils = {
  async sendSuccess(text) {
    await store.dispatch(FM_STORE_KEY, { text, type: FM_SUCCESS });
  },

  async sendError(text) {
    await store.dispatch(FM_STORE_KEY, { text, type: FM_ERROR });
  },
};

export default FlashUtils;
