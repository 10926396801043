import Axios from 'axios';
import AuthService from '@/services/auth.service';
import store from '@/store';
import FlashUtils from '@/utils/flash.utils';

const AxiosGstService = {
  init() {
    this.axios = Axios.create({
      headers: { 'Content-Type': 'application/json' },
      baseURL: process.env.VUE_APP_GST_URL,
    });

    this.axios.interceptors.response.use(
      (config) => config,
      async (error) => {
        // Logs out whenever there is an unauthorized response from the ues.
        // TODO: good as there is only one role permitted on the dashboard (admin) for now!
        if (error.response && error.response.status === 401) {
          await store.dispatch('auth/logout');
          await FlashUtils.sendError(this.$t('common.messages.disconnected'));
        }

        return Promise.reject(error);
      },
    );
  },

  /**
   * Sends a GET request with a json body to the GST.
   * @param {String} path A relative path to the resource.
   * @param {*} withAuth True if the auth header should be set.
   * @param {*} query Object used to create a query string (if not null, path is considered not to contain a query string).
   */
  async get(path, withAuth = true, query = null) {
    let queryString = '';
    if (query && query instanceof Object) {
      Object.entries(query).forEach(([key, value], index) => {
        queryString += index > 0 ? '&' : '?';
        queryString += `${key}=${value}`;
      });
    }

    return this.axios.get(path + queryString, {
      headers: withAuth ? this.authorizationHeader() : {},
    });
  },

  /**
   * Creates an authorization header if ther is a token.
   * Returns an empty object otherwise.
   */
  authorizationHeader() {
    const user = AuthService.getUser();
    if (user && user.token) {
      return { Authorization: `Bearer ${user.token}` };
    }

    return {};
  },
};

export default AxiosGstService;
