<template>
  <v-app v-if="loggedIn">
    <app-navigation/>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <flash-messages/>
  </v-app>
  <v-app v-else>
    <v-main>
      <login-form/>
    </v-main>
    <flash-messages/>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import FlashUtils from '@/utils/flash.utils';
import AppNavigation from './components/AppNavigation.vue';
import LoginForm from './components/LoginForm.vue';
import FlashMessages from './components/flash/FlashMessages.vue';

export default {
  name: 'app',
  components: {
    AppNavigation,
    LoginForm,
    FlashMessages,
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/isLoggedIn',
    }),
  },
  watch: {
    loggedIn() {
      if (!this.loggedIn) {
        FlashUtils.sendError('You have been disconnected!');
      }
    },
  },
};
</script>
