import Axios from 'axios';

const AxiosService = {
  init() {
    this.axios = Axios.create({
      headers: { 'Content-Type': 'application/json' },
      baseURL: process.env.VUE_APP_IDM_URL,
    });
  },
  /**
   * Sends a GET request with a json body to the API.
   * @param {String} path A relative path to the resource.
   * @param {*} withAuth True if the auth header should be set.
   */
  async get(path, withAuth = true) {
    return this.axios.get(path, {
      headers: withAuth ? this.authorizationHeader() : {},
    });
  },

  /**
   * Sends a POST request with a json body to the API.
   * @param {String} path A relative path to the ressource.
   * @param {*} body The request's body.
   * @param {*} withAuth True if the auth header should be set.
   */
  async post(path, body = {}, withAuth = true) {
    return this.axios.post(path, body, {
      headers: withAuth ? this.authorizationHeader() : {},
    });
  },

  /**
   * Sends a PATCH request with a json body to the API.
   * @param {String} path A relative path to the ressource.
   * @param {*} body The request's body.
   * @param {*} withAuth True if the auth header should be set.
   */
  async patch(path, body = {}, withAuth = true) {
    return this.axios.patch(path, body, {
      headers: withAuth ? this.authorizationHeader() : {},
    });
  },
  /**
   * Sends a PT request with a json body to the API.
   * @param {String} path A relative path to the ressource.
   * @param {*} body The request's body.
   * @param {*} withAuth True if the auth header should be set.
   */
  async put(path, body = {}, withAuth = true) {
    return this.axios.put(path, body, {
      headers: withAuth ? this.authorizationHeader() : {},
    });
  },

  /**
   * Sends a DELETE request with a json body to the API.
   * @param {String} path A relative path to the ressource.
   * @param {*} withAuth True if the auth header should be set.
   */
  async delete(path, withAuth = true) {
    return this.axios.delete(path, {
      headers: withAuth ? this.authorizationHeader() : {},
    });
  },

  /**
   * Creates an authorization header if ther is a token.
   * Returns an empty object otherwise.
   */
  authorizationHeader() {
    const user = JSON.parse(window.localStorage.getItem('user') || null);
    if (user && user.token) {
      return { Authorization: `Bearer ${user.token}` };
    }

    return {};
  },
};

export default AxiosService;
