<template>
  <v-scroll-y-reverse-transition group>
    <flash-message v-for="message in messages" :key="message.key"
      :message="message" />
  </v-scroll-y-reverse-transition>
</template>

<script>
import { mapGetters } from 'vuex';
import FlashMessage from '@/components/flash/FlashMessage.vue';

export default {
  name: 'flash-messages',
  components: {
    FlashMessage,
  },
  computed: {
    ...mapGetters({
      messages: 'flash/messages',
    }),
  },
};
</script>
