import languages from '@/locals/languages';

const LOCALE_KEY = 'locale';
export default {
  namespaced: true,
  state: {
    name: languages.at(0).name,
    code: languages.at(0).code,
  },

  getters: {
    name({ name }) {
      return name;
    },
    code({ code }) {
      return code;
    },
  },

  mutations: {
    setLocale(state, locale) {
      state.name = locale.name;
      state.code = locale.code;

      window.localStorage.setItem(LOCALE_KEY, JSON.stringify(locale));
    },
  },

  actions: {
    async load({ commit }) {
      const locale = window.localStorage.getItem(LOCALE_KEY);
      if (locale) {
        const parsedLocale = JSON.parse(locale);
        commit('setLocale', parsedLocale);
      } else {
        commit('setLocale', languages.at(0));
      }
    },

    async changeLocale({ commit }, locale) {
      commit('setLocale', locale);
    },
  },
};
