<template>
  <v-navigation-drawer
    app dark permanent
    :mini-variant="$vuetify.breakpoint.smAndDown && !navOpened"
    :temporary="navOpened && $vuetify.breakpoint.smAndDown"
    v-click-outside="clickOutsideNav"
  >

    <div style="display: flex; flex-direction: row; justify-content: center; padding-top: 5px">
      <v-btn class="vue-select" @click="toggleDropdown">
        <span class="selected-option"><lang-flag :iso="this.$store.state.locale.code" :title="this.$store.state.locale.name"/> {{ $vuetify.breakpoint.smAndDown ? '' : this.$store.state.locale.name }}</span>
        <transition name="slide">
          <div id="nav-dropdown" class="dropdown-options-container" v-show="showDropdown">
            <div v-bind:key="option.value" class="dropdown-options" v-for="option in options" v-bind:class="{'selected': option === $store.state.locale.code}">
              <div id="nav-dropdown-options--cell" class="dropdown-options--cell" @click="selectOption(option)"><span class="option-text"><lang-flag :iso="option.code" /> {{ $vuetify.breakpoint.smAndDown ? '' : option.name }}</span></div>
            </div>
          </div>
        </transition>
      </v-btn>
    </div>
    <v-list nav>
      <v-list-item v-if="$vuetify.breakpoint.smAndDown" @click.stop="navOpened = !navOpened">
        <v-list-item-icon>
            <v-icon>
              {{ navOpened ? 'mdi-close' : 'mdi-menu' }}
            </v-icon>
        </v-list-item-icon>
        <v-list-item-title>
              PREGrec
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-else to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="title">
            PREGrec
        </v-list-item-title>
      </v-list-item>

      <!-- navigation links -->
      <v-list-item v-for="item in authorizedItems" :key="item.title" link :to="item.href">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('pages.' + item.title + '.title')  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list nav style="position: fixed; bottom: 0; width: 100%">
      <v-list-item v-for="item in authorizedBottomItems" :key="item.title" link :to="item.href">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('items.' + item.title)  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import LangFlag from 'vue-lang-code-flags';
import i18n from '@/locals/i18n';
import store from '@/store';
import languages from '@/locals/languages';

export default {
  components: {
    LangFlag,
  },
  name: 'app-navigation',
  data() {
    return {
      options: languages,
      showDropdown: false,
      navOpened: false,
      items: [
        {
          href: '/notifications',
          title: 'notifications',
          icon: 'mdi-bell-cog',
          roles: ['admin', 'redactor'],
        },
        {
          href: '/weekEvents',
          title: 'week_events',
          icon: 'mdi-calendar',
          roles: ['admin', 'redactor'],
        },
        {
          href: '/forms',
          title: 'forms',
          icon: 'mdi-newspaper-variant-outline',
          roles: ['admin', 'redactor'],
        },
        {
          href: '/choiceCollections',
          title: 'choice_collection_info',
          icon: 'mdi-clipboard-list-outline',
          roles: ['admin', 'redactor'],
        },
        {
          href: '/rewardDefinitions',
          title: 'reward_definitions',
          icon: 'mdi-medal',
          roles: ['admin'],
        },
        {
          href: '/leaderboardDefinitions',
          title: 'leaderboard_definitions',
          icon: 'mdi-podium',
          roles: ['admin'],
        },
        {
          href: '/gameRules',
          title: 'game_rules',
          icon: 'mdi-gamepad-square',
          roles: ['admin'],
        },
        {
          href: '/concours',
          title: 'competition',
          icon: 'mdi-trophy',
          roles: ['admin', 'redactor'],
        },
        {
          href: '/admins',
          title: 'admins',
          icon: 'mdi-account-box-outline',
          roles: ['admin'],
        },
      ],
      bottomItems: [
        {
          href: '/logout',
          title: 'logout',
          icon: 'mdi-exit-to-app',
        },
      ],
    };
  },
  computed: {
    authorizedItems() {
      return this.items.filter(({ roles }) => !roles || roles.includes(this.role));
    },
    authorizedBottomItems() {
      return this.bottomItems.filter(({ roles }) => !roles || roles.includes(this.role));
    },
    ...mapGetters('auth', ['role']),
  },
  methods: {
    clickOutsideNav() {
      this.navOpened = false;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async selectOption(option) {
      await store.dispatch('locale/changeLocale', option)
        .then(i18n.locale = this.$store.state.locale.code);
    },
  },
};
</script>
<style>
#nav-dropdown {
  background-color: #424242;
}
#nav-dropdown-options--cell {
  background-color: #424242;
}
#nav-dropdown-options--cell:hover {
  background-color: rgb(96, 97, 97, 0.5);
  border: none;
}
</style>
