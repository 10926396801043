<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="5" xl=4>
        <v-card class="elevation-12 mt-16">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('pages.login.title', {app_name: 'PREGrec'}) }}</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-form>
              <v-text-field :label="$t('common.labels.email_address')" name="email" type="text"
                prepend-icon="mdi-account" :error="loginError"
                v-model="email"/>

              <v-text-field id="password" :label="$t('common.labels.password')" name="password"
                :type="showPassword ? 'text' : 'password'" prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :error="loginError"
                v-model="password"/>
            </v-form>

            <v-alert v-model="loginError" close-text="Close Alert"
              text type="error" dismissible>
              {{ $t('errors.login_error') }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <div style="display: flex; flex-direction: row; justify-content: center; padding-top: 5px">
              <v-btn class="vue-select" @click="toggleDropdown">
                <span class="selected-option"><lang-flag :iso="this.$store.state.locale.code" :title="this.$store.state.locale.name"/> {{ this.$store.state.locale.name }}</span>
                <transition name="slide">
                  <div class="dropdown-options-container" v-show="showDropdown">
                    <div v-bind:key="option.value" class="dropdown-options" v-for="option in options" v-bind:class="{'selected': option === $store.state.locale.code}">
                      <div class="dropdown-options--cell" @click="selectOption(option)"><span class="option-text"><lang-flag :iso="option.code" /> {{ option.name }}</span></div>
                    </div>
                  </div>
                </transition>
              </v-btn>
            </div>

            <v-spacer></v-spacer>

            <v-btn depressed color="primary" @click="submit"
              :disabled="loading" :loading="loading">
              {{ $t('common.action.login') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LangFlag from 'vue-lang-code-flags';
import i18n from '@/locals/i18n';
import store from '@/store';
import languages from '@/locals/languages';

export default {
  name: 'login-form',
  components: { LangFlag },
  data() {
    return {
      options: languages,
      showDropdown: false,
      navOpened: false,
      showPassword: false,
      loginError: false,
      email: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loginError = false;
      if (this.email.length === 0 || this.password.length === 0) {
        this.loginError = true;
        return;
      }

      const credentials = {
        email: this.email,
        password: this.password,
      };

      this.loading = true;
      this.$store.dispatch('auth/login', credentials)
        .then((ok) => {
          if (!ok) {
            this.loginError = true;
          }
        }).finally(() => {
          this.loading = false;
        });
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async selectOption(option) {
      await store.dispatch('locale/changeLocale', option).then(i18n.locale = this.$store.state.locale.code);
    },
  },
};
</script>
<style>
.vue-select .selected-option {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  border-radius: 4px;
  transition: all 200ms linear;
}

.dropdown-options-container {
  margin-top: 156px;
  position: fixed;
  background-color: white;
  height: 100px;
  width: fit-content;
  border-radius: 4px;
  z-index: 99;
}

.dropdown-options--cell {
  border-radius: 4px;
  background-color: white;
  padding: 15px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-options--cell:hover {
  background-color: rgb(96, 97, 97, 0.5);
  border: none;
}
</style>
