const messages = {
  // FRANCAIS
  fr: {
    placeholders: {
      date: 'JJ.MM.AAAA',
    },
    items: {
      logout: 'Déconnexion',
    },
    errors: {
      basic: 'Une erreur est survenue !',
      choose_form: 'Veuillez choisir un questionnaire.',
      choose_in_list: 'Veuillez choisir un.e {name} de la liste.',
      date_max_min: 'La date maximum doit être après la date minimum.',
      date_min_max: 'La date minimum doit être avant la date maximum.',
      email: 'Veuillez saisir un e-mail correct.',
      empty: '{the}{name} ne peut(vent) pas être vide(s).',
      enter_contest_id: 'Veuillez saisir l\'identifiant du concours.',
      enter_coordinator_email: 'Veuillez saisir l\'e-mail du coordinateur',
      enter_message: 'Veuillez saisir un message.',
      invalid_date: 'Veuillez enter une date valide.',
      invalid_number: 'Veuillez enter un nombre entier valide.',
      login_error: 'L\'adresse e-mail ou le mot de passe est incorrect.',
      max_min: 'Le maximum ne peut pas être plus petit que le minimum.',
      min_example_max: 'L\'example de réponse doit être dans l\'échelle définie par le minimum et le maximum.',
      min_max: 'Le minimum ne peut pas être plus grand que le maximum.',
      points: 'Les points doivent être positifs.',
      unavailable: 'Aucun.e.s {name} disponible !',
    },
    common: {
      words: {
        all: 'Tous',
        answer: 'Réponse',
        answers: 'Réponses',
        action: 'Action',
        button: 'Bouton',
        buttons: 'Boutons',
        creation: 'création',
        deletion: 'suppression',
        modification: 'modification',
        end: 'Fin',
        event: 'Événement',
        help: 'Aide',
        filters: 'Filtres',
        form: 'Questionnaire',
        forms: 'Questionnaires',
        from: 'De',
        linked_reward: 'Récompense liée',
        madmin: 'administrateur',
        mreward: 'récompense',
        mrule: 'règle',
        maction: 'action',
        manswer: 'réponse',
        mevent: 'événement',
        mevents: 'événements',
        mmin: 'min',
        mmax: 'max',
        moptional: 'optionnel',
        mquestion: 'question',
        mranking: 'classement',
        no: 'Non',
        none: 'Aucun',
        other: 'Autre',
        questions: 'Questions',
        ranking: 'Classement',
        rewards: 'Récompenses',
        start: 'Début',
        to: 'À',
        the_female: 'la ',
        The_female: 'La ',
        the_male: 'le ',
        The_male: 'Le ',
        the_apostrophe: 'l\'',
        The_apostrophe: 'L\'',
        the_plural: 'les ',
        The_plural: 'Les ',
        week: 'Semaine',
        yes: 'Oui',
      },
      messages: {
        welcome: 'Bienvenue sur le dashboard de {app_name}',
        export_options: 'Options d\'export',
        are_you_sure: 'Êtes-vous sûr.e ?',
        a_new_fem: 'une nouvelle',
        a_new_masc: 'un nouveau',
        a_new_masc_fem: 'un nouvel',
        success: 'La {action} a réussi.',
        failure: 'La {action} a échoué.',
        delay_form: 'Renvoyer le questionnaire une semaine plus tard',
        disconnected: 'Vous avez été déconnecté.e',
        go_to: 'Aller au ',
        go_to_question: 'Aller à la question #',
        end_form: 'Terminer le questionnaire',
        end_research: 'Terminer la recherche et envoyer le questionnaire #{formId}',
        end_research_simple: 'Terminer la recherche',
        next_component: 'Aller au composant #{component}',
        not_known: 'Je ne sais pas',
        no_answer: 'Je ne souhaite pas répondre',
        term: 'le terme de la grossesse',
        delivery: 'la date de l\'accouchement',
        choose_question: 'Veuillez choisir une question',
        branching_logic: 'logique de branchement',
        question_wording: 'Libellé de la question',
        current_selection: 'Séléction actuelle',
        possible_choices: 'Choix possibles',
      },
      labels: {
        article: 'Article',
        account: 'Utilisat.rice.eur',
        answer: 'Réponse',
        answer_sample: 'Exemple de réponse',
        date: 'Date',
        description: 'Description',
        email: 'Email',
        email_address: 'Adresse e-mail',
        event_name: 'Nom de l\'événement',
        execute_action: 'Exécuter l\'action',
        id: 'ID',
        identifier: 'Identifiant',
        if_answer: 'Si la réponse est',
        form: 'Formulaire (id)',
        last_notification: 'Dernière notification',
        message: 'Message',
        midentifier: 'identifiant',
        mname: 'nom',
        mpoints: 'points',
        mwording: 'libellé',
        mvariable: 'variable',
        minimum: 'Minimum',
        maximum: 'Maximum',
        modification: 'Modification',
        name: 'Nom',
        note: 'Note',
        password: 'Mot de passe',
        points_number: 'Nombre de points',
        points: 'Points',
        position: 'Position',
        publication: 'Publication',
        question: 'Question (id)',
        rank: 'Rang',
        score: 'Score',
        show_question: 'Afficher la question',
        state: 'État',
        status: {
          active: 'Actif',
          deactivated: 'Désactivé',
          inactive: 'Inactif',
          name: 'Status',
        },
        title: 'Titre',
        type: 'Type',
        username: 'Nom d\'utilisat.rice.eur',
        variable: 'Variable',
        winner: 'Gagnant.e',
        wording: 'Libellé',
        week_number: 'Numéro de semaine',
        week_type: 'Type de semaine',
      },
      action: {
        add: 'Ajouter',
        back: 'Retour',
        cancel: 'Annuler',
        change: 'Changer',
        choose: 'Choisir',
        close: 'Fermer',
        create: 'Créer',
        delete: 'Supprimer',
        download: 'Télécharger',
        login: 'Se connecter',
        modify: 'Modifier',
        reinitialize: 'Réinitialiser',
        save: 'Sauvegarder',
        search: 'Rechercher',
        show_more: 'Afficher plus',
      },
    },
    pages: {
      admins: {
        title: 'Administrateurs',
        name: 'administrateur',
      },
      choice_collection_info: {
        title: 'Choix de collection',
        choice: 'Choix de la collection',
        name: 'collection',
        choice_message: 'Veuillez choisir une collection de choix.',
        collection_choice: 'Collection de choix',
      },
      choice_collections: {
        title: 'Collections de choix',
        list_title: 'Collections disponibles',
      },
      competition: {
        title: 'Concours',
        ranking: 'Classsement',
        selected_winners: 'Gagnant.e.s sélectionné.e.s',
        identifier: 'Identifiant du concours',
        coordinator_email: 'Email du coordinateur',
        mark_reward_as_sent: 'Voulez-vous vraiment marquer la récompense de ce.tte gagnan.te comme envoyée ?',
        confirm_rule_deletion_1: 'Voulez-vous vraiment supprimer',
        confirm_rule_deletion_2: 'ce.tte gagnant.e de la liste ?',
        confirm_rule_deletion_message: 'Cette action est irréversible.',
        winner_selection: 'Sélection du/de la gagnant.e',
      },
      forms: {
        title: 'Questionnaires',
      },
      form_info: {
        title: 'Questionnaire',
        title_s: 'Questionnaires',
        name: 'questionnaire',
        delete_form_1: 'Voulez-vous vraiment supprimer',
        delete_form_2: 'ce questionnaire ?',
        push_form_1: 'Voulez-vous vraiment envoyer',
        push_form_2: 'ce questionnaire ?',
        push_all_form_3: 'Il sera ajouté à la liste de questionnaires à répondre des tou.te.s les utilisat.rice.eur.s.',
        push_form_3: 'Il sera ajouté à voutre liste de questionnaires à répondre.'
          + ' Vous pourrez y répondre en vous connectant à l\'application mobile.'
          + ' Si vous avez déjà un questionnaire en cours, terminez d\'y répondre.',
        delete_form_3: 'Le questionnaire sera définitivement supprimé. Cette action est irréversible.',
        buttons: {
          modify: 'Modifier le questionnaire (aller à la page de modification)',
          push: 'Pousser le questionnaire pour le compte courant ({username}, {email})',
          push_all: 'Pousser le questionnaire pour tou.te.s les utilisat.rice.eur.s',
          delete: 'Supprimer le questionnaire',
        },
        component: {
          delete_validation_1: 'Êtes-vous sûr de vouloir',
          delete_validation_2: 'supprimer ce composant ?',
          delete_validation_message: 'Le composant sera définitivement supprimé. Cette action est irréversible.',
        },
      },
      form: {
        list_title: 'Composants',
        add_message: 'Quel composant souhaitez-vous ajouter ?',
        display_type: 'Display type',
        question: {
          true_wording_label: 'Libellé de la réponse vraie',
          false_wording_label: 'Libellé de la réponse fausse',
          min_date: 'Date minimum',
          max_date: 'Date maximum',
          update_date: 'Utiliser la réponse pour mettre à jour {event}',
          use_external_choices: 'Utiliser des choix externes',
          multi_selection: 'Sélection multiple',
          other_active: 'Choix \'autre\' actif',
          none_active: 'Choix \'aucun\' actif',
        },
      },
      game_rules: {
        title: 'Règles du jeu',
        list_title: 'Définition des règles de jeux en fonction d\'un événement',
        no_rules: 'Aucune règle disponible !',
        confirm_rule_deletion_1: 'Voulez-vous vraiment supprimer',
        confirm_rule_deletion_2: 'cette règle ?',
        confirm_rule_deletion_message: 'La règle sera définitivement supprimée. Cette action est irréversible.',
      },
      home: {
        title: 'Accueil',
      },
      leaderboard_definitions: {
        title: 'Classement',
        creation_title: 'Créer un nouveau classement',
        rewards_choice: 'Choix des récompenses',
        list_title: 'Définitions des classements',
        delete_validation_1: 'Êtes-vous sûr de vouloir',
        delete_validation_2: 'supprimer ce classement ?',
        delete_validation_message: 'Le classement sera définitivement supprimé. Cette action est irréversible.',
        selected_choices: 'Choix sélectionnés',
        no_selected_rewards: 'Aucune récompense sélectionnée',
        available_choices: 'Choix disponibles',
        all_selected: 'Toutes les récompense ont été sélectionnées.',
        points_for: 'points pour',
      },
      login: {
        title: '{app_name} - Tableau de bord',
      },
      notifications: {
        title: 'Notifications',
        save_params: 'Sauvegarder la fourchette d\'âge gestationel entrée',
        save_params_and_notify: 'Sauvegarder la fourchette d\'âge gestationel entrée et notifier par âge gestationnel',
        notify_all: 'Notifier tout le monde',
        send_param_success: 'La fourchette d\'âge gestationnel a été sauvegardée.',
        send_success: 'La notification a été envoyée.',
      },
      reward_definitions: {
        title: 'Récompenses',
        list_title: 'Définitions des récompenses',
        create_new: 'Créer une nouvelle récompense',
        delete_validation_1: 'Êtes-vous sûr de vouloir supprimer',
        delete_validation_2: 'cette récompense ?',
        delete_validation_message: 'La récompense sera définitivement supprimée. Cette action est irréversible.',
      },
      week_events: {
        title: 'Événements',
        delete_validation_1: 'Êtes-vous sûr de vouloir',
        delete_validation_2: 'supprimer ce choix ?',
        delete_validation_message: 'Le choix sera définitivement supprimé. Cette action est irréversible.',
        group_by_week_type: 'Grouper par type de semaine',
        group_by_form: 'Grouper par formulaire',
        form_in_event_error: 'Ce formulaire est déjà présent dans l\'événement',
        list: {
          delete_validation_1: 'Êtes-vous sûr de vouloir',
          delete_validation_2: 'supprimer cet événement ?',
          delete_validation_message: 'L\'événement sera définitivement supprimé. Cette action est irréversible.',
        },
        help: {
          message: 'Le numéro de semaine max permet de donner une fourchette, si aucune valeur n\'est donnée, seul le numéro de semaine donné est utilisé. À noter que les utilisat.rice.eur.s auront les questionnaires concernés pour chaque semaine dans la fourchette (une fois par semaine contenue dans la fourchette donnée).',
          modification: 'Modifier l\'événement (aller à la page de modification)',
          deletion: 'Supprimer l\'événement',
        },
      },
    },
    event: {
      type: {
        ANSWER_EVENT: 'Réponse',
        WEEK_EVENT: 'Semaine',
      },
    },
    weekType: {
      INSCRIPTION: 'Semaine d\'inscription',
      INSCRIPTION_POSTPARTUM: 'Semaine d\'inscription en post-partum',
      AMENORRHEA: 'Semaine de grossesse',
      POSTPARTUM: 'Semaine après accouchement',
    },
    component: {
      branching_logic: 'Logiques de branchement pour la question',
      TEXT_QUESTION: {
        title: 'Question ouverte',
      },
      NUMBER_QUESTION: {
        title: 'Question numérique',
        display: {
          INPUT: 'Simple',
          SLIDER: 'Curseur',
          RATING: 'Évaluation',
          DROPDOWN_ASC: 'Liste déroulante (tri ascendant)',
          DROPDOWN_DESC: 'Liste déroulante (tri déscendant)',
        },
      },
      BOOLEAN_QUESTION: {
        title: 'Question Oui/Non',
      },
      DATE_QUESTION: {
        title: 'Question de Date',
      },
      MULTIPLE_CHOICE_QUESTION: {
        title: 'Question à choix multiple',
      },
      REPEATING_GROUP: {
        title: 'Groupe de répétition',
        candidate: {
          explanation: 'Cette question numérique sera utilisée pour connaître'
            + ' le nombre de répétitions que le group devra effectuer.'
            + ' Veillez à bien choisir une question qui sera répondue'
            + ' par les utilisat.rice.eur.s avant le groupe de répétition.',
        },
        error: 'Veuillez choisir une question numérique!',
      },
      CHECK_BOX_GROUP: {
        title: 'Groupe de choix multiple',
        branching_logic: 'Logiques de branchement pour le groupe de choix multiple',
        candidate: {
          explanation: 'Cette question à choix multiples sera utilisée pour piloter le groupe et connaître les questions à envoyer.',
        },
        error: 'Veuillez choisir une question à choix multiple !',
      },
      CHECK_BOX_REPEATING_GROUP: {
        title: 'Groupe de répétition pour question à choix multiple',
        candidate: {
          explanation: 'Cette question à choix multiples sera utilisée pour connaître les répétitions à effectuer.',
        },
        error: 'Veuillez choisir une question à choix multiple !',
      },
    },
  },

  // ENGLISH
  en: {
    placeholders: {
      date: 'DD.MM.YYYY',
    },
    items: {
      logout: 'Logout',
    },
    errors: {
      basic: 'An error has occurred!',
      choose_in_list: 'Please choose a/an {name} from the list.',
      choose_form: 'Please choose a form.',
      date_max_min: 'The maximum date must be after the minimum date.',
      date_min_max: 'The minimum date must be before the maximum date.',
      email: 'Please enter a correct email.',
      empty: '{the}{name} cannot be empty.',
      enter_contest_id: 'Please enter the contest identifier.',
      enter_coordinator_email: 'Please enter the coordinator\'s email',
      enter_message: 'Please enter a message.',
      invalid_date: 'Please enter a valid date.',
      invalid_number: 'Please enter a valid number.',
      login_error: 'The email or password is incorrect.',
      max_min: 'The maximum cannot be lower than the minimum.',
      min_example_max: 'The sample answer must be within the range defined by the minimum and maximum.',
      min_max: 'The minimum cannot be greater than the maximum.',
      points: 'The points must be positive.',
      unavailable: 'No {name} available!',
    },
    common: {
      words: {
        all: 'All',
        action: 'Action',
        answer: 'Answer',
        answers: 'Answers',
        button: 'Button',
        buttons: 'Buttons',
        creation: 'creation',
        deletion: 'deletion',
        modification: 'modification',
        end: 'End',
        event: 'Event',
        filters: 'Filters',
        form: 'Form',
        forms: 'Forms',
        from: 'From',
        help: 'Help',
        linked_reward: 'Linked reward',
        maction: 'action',
        madmin: 'administrator',
        manswer: 'answer',
        manswers: 'answers',
        mevent: 'event',
        mevents: 'events',
        mmin: 'min',
        mmax: 'max',
        moptional: 'optional',
        mranking: 'ranking',
        mreward: 'reward',
        mrule: 'rule',
        no: 'No',
        none: 'None',
        other: 'Other',
        questions: 'Questions',
        ranking: 'Ranking',
        rewards: 'Rewards',
        start: 'Start',
        to: 'To',
        the_apostrophe: 'the ',
        The_apostrophe: 'The ',
        the_female: 'the ',
        The_female: 'The ',
        the_male: 'the ',
        The_male: 'The ',
        the_plural: 'the ',
        The_plural: 'The ',
        week: 'Week',
        yes: 'Yes',
      },
      messages: {
        welcome: 'Welcome to the {app_name} dashboard',
        export_options: 'Export options',
        are_you_sure: 'Are you sure?',
        a_new_fem: 'a new',
        a_new_masc: 'a new',
        a_new_masc_fem: 'a new',
        success: 'The {action} was a success.',
        failure: 'The {action} failed.',
        delay_form: 'Resend the questionnaire one week later',
        disconnected: 'You have been disconnected.',
        go_to: 'Go to ',
        go_to_question: 'Go to question #',
        end_form: 'Complete the quiz',
        end_research: 'End search and send questionnaire {formId}',
        end_research_simple: 'End the search',
        next_component: 'Go to component {component}',
        not_known: 'I don\'t know',
        no_answer: 'I don\'t want to answer',
        term: 'the term of pregnancy date',
        delivery: 'the date of delivery',
        choose_question: 'Please choose a question',
        branching_logic: 'branching logic',
        question_wording: 'Question wording',
        current_selection: 'Current selection',
        possible_choices: 'Possible choices',
      },
      labels: {
        answer: 'Answer',
        account: 'User',
        answer_sample: 'Sample answer',
        date: 'Date',
        article: 'Article',
        description: 'Description',
        email: 'Email',
        email_address: 'Email address',
        event_name: 'Event name',
        execute_action: 'Execute the action',
        id: 'ID',
        identifier: 'Identifier',
        if_answer: 'If the answer is',
        form: 'Form (id)',
        last_notification: 'Last notification',
        maximum: 'Maximum',
        message: 'Message',
        midentifier: 'identifier',
        minimum: 'Minimum',
        mname: 'name',
        modification: 'Modification',
        mpoints: 'points',
        musername: 'username',
        mvariable: 'variable',
        mwording: 'wording',
        name: 'Name',
        note: 'Note',
        password: 'Password',
        publication: 'Publication',
        points: 'Points',
        position: 'Position',
        points_number: 'Number of points',
        question: 'Question (id)',
        rank: 'Rank',
        score: 'Score',
        show_question: 'Show the question',
        state: 'State',
        status: {
          name: 'Status',
          active: 'Active',
          inactive: 'Inactive',
          deactivated: 'Deactivated',
        },
        title: 'Title',
        type: 'Type',
        username: 'Username',
        variable: 'Variable',
        week_number: 'Week number',
        week_type: 'Week type',
        winner: 'Winner',
        wording: 'Wording',
      },
      action: {
        add: 'Add',
        back: 'Back',
        cancel: 'Cancel',
        create: 'Create',
        choose: 'Choose',
        change: 'Change',
        close: 'Close',
        download: 'Download',
        login: 'Login',
        delete: 'Delete',
        modify: 'Update',
        reinitialize: 'Reinitialize',
        save: 'Save',
        search: 'Search',
        show_more: 'Show more',
      },
    },
    pages: {
      admins: {
        title: 'Administrators',
        name: 'administrator',
      },
      choice_collection_info: {
        title: 'Choice of collection',
        choice: 'Choices of the collection',
        name: 'collection',
        choice_message: 'Please choose a collection of choice.',
        collection_choice: 'Collection of choices',
      },
      choice_collections: {
        title: 'Choice collections',
        list_title: 'Available choice collections',
      },
      competition: {
        title: 'Competition',
        ranking: 'Ranking',
        selected_winners: 'Selected winners',
        identifier: 'Competition identifier',
        coordinator_email: 'Coordinator\'s email',
        mark_reward_as_sent: 'Are you sure you want to mark this winner\'s reward as sent?',
        confirm_rule_deletion_1: 'Are you sure you want to delete',
        confirm_rule_deletion_2: 'this list winner?',
        confirm_rule_deletion_message: 'This action is irreversible.',
        winner_selection: 'Selection of the winner',
      },
      forms: {
        title: 'Forms',
      },
      form_info: {
        title: 'Form',
        title_s: 'Forms',
        name: 'form',
        push_form_1: 'Are you sure you want to send',
        delete_form_1: 'Are you sure you want to delete',
        push_form_2: 'this form ?',
        delete_form_2: 'this form ?',
        push_all_form_3: 'It will be added to the list of questionnaires to be answered by all users.',
        push_form_3: 'It will be added to your list of questionnaires to answer.'
          + ' You can answer them by logging into the mobile application.'
          + ' If you already have a quiz in progress, finish answering it.',
        delete_form_3: 'The questionnaire will be permanently deleted. This action is irreversible.',
        buttons: {
          modify: 'Modify the form (go to the modification page)',
          push: 'Push the form for the current account ({username}, {email})',
          push_all: 'Push the form for all users',
          delete: 'Delete the form',
        },
        component: {
          delete_validation_1: 'Are you sure you want to',
          delete_validation_2: 'remove this component?',
          delete_validation_message: 'The component will be permanently deleted. This action is irreversible.',
        },
      },
      form: {
        list_title: 'Components',
        add_message: 'What component do you want to add?',
        display_type: 'Display type',
        question: {
          true_wording_label: 'Label of the \'true\' answer',
          false_wording_label: 'Label of the \'false\' answer',
          min_date: 'Minimum date',
          max_date: 'Maximum date',
          update_date: 'Use response to update {event}',
          use_external_choices: 'Use external choices',
          multi_selection: 'Multiple selection',
          other_active: 'Activate the \'other\' choice',
          none_active: 'Activate the \'none\' choice',
        },
      },
      game_rules: {
        title: 'Game rules',
        list_title: 'Definition of game rules according to an event',
        no_rules: 'No rules available!',
        confirm_rule_deletion_1: 'Are you sure you want to delete',
        confirm_rule_deletion_2: ' this rule?',
        confirm_rule_deletion_message: 'The rule will be permanently deleted. This action is irreversible.',
      },
      home: {
        title: 'Home',
      },
      leaderboard_definitions: {
        title: 'Leaderboard',
        creation_title: 'Create a new ranking',
        rewards_choice: 'Choice of rewards',
        list_title: 'Rating definitions',
        delete_validation_1: 'Are you sure you want to delete',
        delete_validation_2: 'this ranking?',
        delete_validation_message: 'The ranking will be permanently deleted. This action is irreversible.',
        selected_choices: 'Selected choices',
        no_selected_rewards: 'No reward selected',
        available_choices: 'Available choices',
        all_selected: 'All rewards have been selected.',
        points_for: 'points for',
      },
      login: {
        title: '{app_name} - Dashboard',
      },
      notifications: {
        title: 'Notifications',
        save_params: 'Save entered gestational age range',
        save_params_and_notify: 'Save entered gestational age range and notify by gestational age',
        notify_all: 'Notify all users',
        send_param_success: 'The gestational age range has been saved.',
        send_success: 'The notification has been sent.',
      },
      reward_definitions: {
        title: 'Rewards',
        list_title: 'Rewards definitions',
        create_new: 'Create a new reward',
        delete_validation_1: 'Are you sure you want to delete',
        delete_validation_2: 'this reward?',
        delete_validation_message: 'The reward will be permanently deleted. This action is irreversible.',
      },
      week_events: {
        title: 'Events',
        delete_validation_1: 'Are you sure you want to delete',
        delete_validation_2: 'this choice?',
        delete_validation_message: 'The choice will be permanently deleted. This action is irreversible.',
        group_by_week_type: 'Group by type of week',
        group_by_form: 'Group by form',
        form_in_event_error: 'The form is already in the event.',
        list: {
          delete_validation_1: 'Are you sure you want to delete',
          delete_validation_2: 'this event?',
          delete_validation_message: 'The event will be permanently deleted. This action is irreversible.',
        },
        help: {
          message: 'The max week number allows to give a range, if no value is given, only the given week number is used. Note that users will have the questionnaires concerned for each week in the range (once a week contained in the given range).',
          modification: 'Edit event (go to edit page)',
          deletion: 'Delete event',
        },
      },
    },
    event: {
      type: {
        ANSWER_EVENT: 'Answer',
        WEEK_EVENT: 'Week',
      },
    },
    weekType: {
      INSCRIPTION: 'Registration week\n',
      INSCRIPTION_POSTPARTUM: 'Postpartum registration week',
      AMENORRHEA: 'Week of pregnancy',
      POSTPARTUM: 'Week after delivery',
    },
    component: {
      branching_logic: 'Branching logics for the question',
      TEXT_QUESTION: {
        title: 'Open question',
      },
      NUMBER_QUESTION: {
        title: 'Numeric question',
        display: {
          INPUT: 'Simple',
          SLIDER: 'Slider',
          RATING: 'Rating',
          DROPDOWN_ASC: 'Dropdown (ascending sort)',
          DROPDOWN_DESC: 'Dropdown (descending sort)',
        },
      },
      BOOLEAN_QUESTION: {
        title: 'Yes/No question',
      },
      DATE_QUESTION: {
        title: 'Date question',
      },
      MULTIPLE_CHOICE_QUESTION: {
        title: 'Multiple choice question',
      },
      REPEATING_GROUP: {
        title: 'Repeating group',
        candidate: {
          explanation: 'This numerical question will be used to know'
            + ' the number of repetitions that the group will have to perform.'
            + ' Be sure to choose a question that will be answered by'
            + ' the users before the practice group.',
        },
        error: 'Please choose a numerical question!',
      },
      CHECK_BOX_GROUP: {
        title: 'Multiple choice group',
        branching_logic: 'Branching logics for multiple choice group',
        candidate: {
          explanation: 'This multiple choice question will be used to pilot the group and know the questions to send.',
        },
        error: 'Please choose a multiple choice question!',
      },
      CHECK_BOX_REPEATING_GROUP: {
        title: 'Repeating group for multiple choice question',
        candidate: {
          explanation: 'This multiple choice question will be used to know the repetitions to perform.',
        },
        error: 'Please choose a multiple choice question!',
      },
    },
  },

  // GERMAN
  de: {
    placeholders: {
      date: 'TT.MM.JJJJ',
    },
    items: {
      logout: 'Abmelden',
    },
    errors: {
      basic: 'Ein Fehler ist aufgetreten.',
      choose_form: 'Bitte wählen Sie ein Formular aus.',
      choose_in_list: 'Bitte wählen Sie einen Wert aus der Liste aus.',
      date_max_min: 'Das maximale Datum muss nach dem minimalen Datum liegen.',
      date_min_max: 'Das minimale Datum muss vor dem maximalen Datum liegen.',
      email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      empty: '{the}{name} darf nicht leer sein.',
      enter_contest_id: 'Bitte geben Sie die ID des Wettbewerbs ein.',
      enter_coordinator_email: 'Bitte geben Sie die E-Mail-Adresse des Koordinators ein.',
      enter_message: 'Bitte geben Sie eine Nachricht ein.',
      invalid_date: 'Bitte geben Sie ein gültiges Datum ein.',
      invalid_number: 'Bitte geben Sie eine gültige Zahl ein.',
      login_error: 'Die E-Mail-Adresse oder das Passwort ist falsch.',
      max_min: 'Das Maximum darf nicht kleiner als das Minimum sein.',
      min_example_max: 'Das Minimum darf nicht größer als das Maximum sein.',
      min_max: 'Das Minimum darf nicht größer als das Maximum sein.',
      points: 'Bitte geben Sie eine gültige Punktzahl ein.',
      unavailable: 'Kein.e.n {name} verfügbar !',
    },
    common: {
      words: {
        all: 'Alle',
        answer: 'Antwort',
        answers: 'Antworten',
        action: 'Aktion',
        button: 'Knopf',
        buttons: 'Knöpfe',
        creation: 'Schaffung',
        deletion: 'Unterdrückung',
        modification: 'Änderung',
        end: 'Ende',
        event: 'Ereignis',
        help: 'Hilfe',
        filters: 'Filter',
        form: 'Formular',
        forms: 'Formulare',
        from: 'Von',
        linked_reward: 'Verknüpfte Belohnung',
        madmin: 'Admin',
        mreward: 'Belohnung',
        mrule: 'Regel',
        maction: 'Aktion',
        manswer: 'Antwort',
        mevent: 'Ereignis',
        mevents: 'Ereignisse',
        mmin: 'Min',
        mmax: 'Max',
        moptional: 'Optional',
        mquestion: 'Frage',
        mranking: 'Rangliste',
        no: 'Nein',
        none: 'Keine',
        other: 'Andere',
        questions: 'Fragen',
        ranking: 'Rangliste',
        rewards: 'Belohnungen',
        start: 'Start',
        to: 'Bis',
        the_female: 'die ',
        The_female: 'Die ',
        the_male: 'der ',
        The_male: 'Der ',
        the_apostrophe: 'des ',
        The_apostrophe: 'Des ',
        the_plural: 'die ',
        The_plural: 'Die ',
        week: 'Woche',
        yes: 'Ja',
      },
      messages: {
        welcome: 'Willkommen im Dashboard von {app_name}',
        export_options: 'Exportoptionen',
        are_you_sure: 'Sind Sie sicher?',
        a_new_fem: 'eine neue',
        a_new_masc: 'ein neuer',
        a_new_masc_fem: 'ein neues',
        success: 'Die {action} war erfolgreich.',
        failure: 'Die {action} ist fehlgeschlagen.',
        delay_form: 'Senden Sie den Fragebogen eine Woche später erneut',
        disconnected: 'Deine Verbindung wurde getrennt.',
        go_to: 'Gehe zu ',
        go_to_question: 'Gehe zur Frage #',
        end_form: 'Schließe das Quiz ab',
        end_research: 'Suche beenden und Fragebogen senden {formId}',
        end_research_simple: 'Beenden Sie die Suche',
        next_component: 'Gehe zur Komponente {component}',
        not_known: 'Ich weiß es nicht',
        no_answer: 'Keine Antwort',
        term: 'Dauer der Schwangerschaft',
        delivery: 'das Lieferdatum',
        choose_question: 'Bitte wählen Sie eine Frage aus',
        branching_logic: 'Verzweigungslogik',
        question_wording: 'Formulierung der Frage',
        current_selection: 'Aktuelle Auswahl',
        possible_choices: 'Mögliche Antworten',
      },
      labels: {
        article: 'Artikel',
        account: 'Benutzer',
        answer: 'Antwort',
        answer_sample: 'Beispielantwort',
        date: 'Datum',
        description: 'Beschreibung',
        email: 'E-Mail-Adresse',
        email_address: 'E-Mail-Adresse',
        event_name: 'Name des Ereignisses',
        execute_action: 'Aktion ausführen',
        id: 'ID',
        identifier: 'Identifikator',
        if_answer: 'Wenn die Antwort lautet',
        form: 'Form (id)',
        last_notification: 'Neueste Benachrichtigung',
        message: 'Nachricht',
        midentifier: 'Identifikator',
        mname: 'Name',
        mpoints: 'Punkte',
        mwording: 'Formulierung',
        mvariable: 'Variable',
        minimum: 'Minimum',
        maximum: 'Maximum',
        modification: 'Änderung',
        name: 'Name',
        note: 'Hinweis',
        password: 'Passwort',
        points_number: 'Anzahl der Punkte',
        points: 'Punkte',
        position: 'Position',
        publication: 'Veröffentlichung',
        question: 'Frage (id)',
        rank: 'Rang',
        score: 'Punktzahl',
        show_question: 'Frage anzeigen',
        state: 'Status',
        status: {
          active: 'Aktiv',
          deactivated: 'Deaktiviert',
          inactive: 'Inaktiv',
          name: 'Status',
        },
        title: 'Titel',
        type: 'Typ',
        username: 'Nutzername',
        variable: 'Variable',
        winner: 'Gewinner',
        wording: 'Formulierung',
        week_number: 'Nummer der Woche',
        week_type: 'Typ der Woche',
      },
      action: {
        add: 'Hinzufügen',
        back: 'Zurück',
        cancel: 'Abbrechen',
        change: 'Ändern',
        choose: 'Wählen',
        close: 'Schließen',
        create: 'Erstellen',
        delete: 'Löschen',
        download: 'Herunterladen',
        login: 'Anmelden',
        modify: 'Ändern',
        reinitialize: 'Zurücksetzen',
        save: 'Speichern',
        search: 'Suchen',
        show_more: 'Mehr anzeigen',
      },
    },
    pages: {
      admins: {
        title: 'Admins',
        name: 'Admin',
      },
      choice_collection_info: {
        title: 'Auswahl der Kollektion',
        choice: 'Auswahl der Kollektion',
        name: 'Kollektion',
        choice_message: 'Bitte wählen Sie eine Kollektion Ihrer Wahl.',
        collection_choice: 'Sammlung von Auswahlmöglichkeiten',
      },
      choice_collections: {
        title: 'Auswahlsammlungen',
        list_title: 'Sammlungen verfügbar',
      },
      competition: {
        title: 'Wettbewerbe',
        ranking: 'Rangliste',
        selected_winners: 'Ausgewählte Gewinner',
        identifier: 'Wettbewerbs-ID',
        coordinator_email: 'E-Mail des Koordinators',
        mark_reward_as_sent: 'Sind Sie sicher, dass Sie die Belohnung dieses Gewinners als eingereicht markieren möchten?',
        confirm_rule_deletion_1: 'Möchten Sie diesen Gewinner',
        confirm_rule_deletion_2: 'wirklich aus der Liste entfernen?',
        confirm_rule_deletion_message: 'Diese Aktion ist irreversibel.',
        winner_selection: 'Auswahl des Gewinners',
      },
      forms: {
        title: 'Fragebögen',
      },
      form_info: {
        title: 'Fragebogen',
        title_s: 'Fragebögen',
        name: 'Fragebogen',
        delete_form_1: 'Möchten Sie dieses Formular',
        delete_form_2: 'wirklich löschen?',
        push_form_1: 'Sind Sie sicher, dass Sie diesen',
        push_form_2: 'Fragebogen senden möchten?',
        push_all_form_3: 'Es wird der Liste der Fragebögen hinzugefügt, die von allen Benutzern beantwortet werden müssen.',
        push_form_3: 'Es wird Ihrer Liste der zu beantwortenden Fragebögen hinzugefügt.'
          + ' Sie können sie beantworten, indem Sie sich bei der mobilen Anwendung anmelden.'
          + ' Wenn Sie bereits ein Quiz in Bearbeitung haben, beenden Sie die Beantwortung.',
        delete_form_3: 'Es wird aus der Liste der Fragebögen entfernt, die von allen Benutzern beantwortet werden müssen.',
        buttons: {
          modify: 'Ändern Sie den Fragebogen (gehen Sie zur Änderungsseite)',
          push: 'Push-Fragebogen für Girokonto ({username}, {email})',
          push_all: 'Pushen Sie den Fragebogen für alle Benutzer',
          delete: 'Quiz löschen',
        },
        component: {
          delete_validation_1: 'Möchten Sie diese Komponente ',
          delete_validation_2: 'wirklich entfernen?',
          delete_validation_message: 'Die Komponente wird dauerhaft gelöscht. Diese Aktion ist irreversibel.',
        },
      },
      form: {
        list_title: 'Fragebögen verfügbar',
        add_message: 'Welche Komponente möchten Sie hinzufügen?',
        display_type: 'Anzeigetyp',
        question: {
          true_wording_label: 'Wortlaut der richtigen Antwort',
          false_wording_label: 'Wortlaut der falschen Antwort',
          min_date: 'Mindestdatum',
          max_date: 'Maximales Datum',
          update_date: 'Antwort verwenden, um {event} zu aktualisieren',
          use_external_choices: 'Nutzen Sie externe Auswahlmöglichkeiten',
          multi_selection: 'Mehrfachauswahl',
          other_active: 'Auswahl \'andere\' aktiv',
          none_active: 'Auswahl \'keine\' aktiv',
        },
      },
      game_rules: {
        title: 'Spielregeln',
        list_title: 'Festlegung von Spielregeln entsprechend einem Ereignis',
        no_rules: 'Keine Regeln verfügbar!',
        confirm_rule_deletion_1: 'Sind Sie sicher, dass Sie',
        confirm_rule_deletion_2: 'diese Regel löschen möchten?',
        confirm_rule_deletion_message: 'Die Regel wird dauerhaft gelöscht. Diese Aktion ist irreversibel.',
      },
      home: {
        title: 'Willkommen',
      },
      leaderboard_definitions: {
        title: 'Ranglisten',
        creation_title: 'Erstellung einer Rangliste',
        rewards_choice: 'Auswahl der Belohnungen',
        list_title: 'Ranglisten verfügbar',
        delete_validation_1: 'Sind Sie sicher, dass Sie',
        delete_validation_2: 'dieses Ranking löschen möchten?',
        delete_validation_message: 'Die Rangliste wird dauerhaft gelöscht. Diese Aktion ist irreversibel.',
        selected_choices: 'Ausgewählte Belohnungen',
        no_selected_rewards: 'Keine Belohnungen ausgewählt',
        available_choices: 'Verfügbare Belohnungen',
        all_selected: 'Alle Belohnungen wurden ausgewählt.',
        points_for: 'Punkte für',
      },
      login: {
        title: '{app_name} – Dashboard',
      },
      notifications: {
        title: 'Benachrichtigungen',
        save_params: 'Speichern Sie den eingegebenen Schwangerschaftsalterbereich',
        save_params_and_notify: 'Speichern Sie den eingegebenen Gestationsalterbereich und benachrichtigen Sie ihn nach Gestationsalter',
        notify_all: 'Benachrichtigen Sie alle Benutzer',
        send_param_success: 'Der Gestationsalterbereich wurde gespeichert.',
        send_success: 'Die Benachrichtigung wurde gesendet.',
      },
      reward_definitions: {
        title: 'Belohnungen',
        list_title: 'Belohnungsdefinitionen',
        create_new: 'Erstellen Sie eine neue Belohnung',
        delete_validation_1: 'Sind Sie sicher, dass Sie diese',
        delete_validation_2: 'Belohnung löschen möchten?',
        delete_validation_message: 'Die Belohnung wird dauerhaft gelöscht. Diese Aktion ist irreversibel.',
      },
      week_events: {
        title: 'Wochenereignisse',
        delete_validation_1: 'Sind Sie sicher, dass Sie diese',
        delete_validation_2: 'Auswahl löschen möchten?',
        delete_validation_message: 'Die Auswahl wird dauerhaft gelöscht. Diese Aktion ist irreversibel.',
        group_by_week_type: 'Gruppieren Sie nach Wochentyp',
        group_by_form: 'Nach Formular gruppieren',
        form_in_event_error: 'Dieses Formular ist bereits in der Veranstaltung vorhanden',
        list: {
          delete_validation_1: 'Sind Sie sicher, dass',
          delete_validation_2: 'Sie dieses Ereignis löschen möchten?',
          delete_validation_message: 'Die Veranstaltung wird endgültig gelöscht. Diese Aktion ist irreversibel.',
        },
        help: {
          message: 'Die maximale Wochennummer ermöglicht die Angabe eines Bereichs. Wenn kein Wert angegeben wird, wird nur die angegebene Wochennummer verwendet. Beachten Sie, dass den Benutzern die betreffenden Fragebögen für jede Woche innerhalb des Bereichs zur Verfügung stehen (einmal pro Woche innerhalb des angegebenen Bereichs).',
          modification: 'Veranstaltung bearbeiten (zur Bearbeitungsseite gehen)',
          deletion: 'Veranstaltung löschen',
        },
      },
    },
    event: {
      type: {
        ANSWER_EVENT: 'Antwort',
        WEEK_EVENT: 'Woche',
      },
    },
    weekType: {
      INSCRIPTION: 'Inskriptionswoche',
      INSCRIPTION_POSTPARTUM: 'Einschulungswoche nach der Geburt',
      AMENORRHEA: 'Woche der Schwangerschaft',
      POSTPARTUM: 'Woche nach der Lieferung',
    },
    component: {
      branching_logic: 'Verzweigungslogiken für die Frage',
      TEXT_QUESTION: {
        title: 'Offene Frage',
      },
      NUMBER_QUESTION: {
        title: 'Numerische Frage',
        display: {
          INPUT: 'Einfach',
          SLIDER: 'Mauszeiger',
          RATING: 'Bewertung',
          DROPDOWN_ASC: 'Dropdown-Liste (aufsteigende Sortierung)',
          DROPDOWN_DESC: 'Dropdown-Liste (absteigende Sortierung)',
        },
      },
      BOOLEAN_QUESTION: {
        title: 'Frage Ja/Nein',
      },
      DATE_QUESTION: {
        title: 'Datumsfrage',
      },
      MULTIPLE_CHOICE_QUESTION: {
        title: 'Frage mit mehreren Antwortmöglichkeiten',
      },
      REPEATING_GROUP: {
        title: 'Gruppe wiederholen',
        candidate: {
          explanation: 'Diese numerische Frage wird verwendet,'
            + ' um die Anzahl der Wiederholungen zu ermitteln,'
            + ' die die Gruppe durchführen muss. Stellen Sie sicher,'
            + ' dass Sie eine Frage auswählen, die von den Benutzern'
            + ' vor der Probengruppe beantwortet wird.',
        },
        error: 'Bitte wählen Sie eine numerische Frage!',
      },
      CHECK_BOX_GROUP: {
        title: 'Multiple-Choice-Gruppe',
        branching_logic: 'Verzweigungslogiken für Multiple-Choice-Gruppen',
        candidate: {
          explanation: 'Diese Multiple-Choice-Frage wird verwendet, um die Gruppe zu steuern und zu ermitteln, welche Fragen gesendet werden sollen.',
        },
        error: 'Bitte wählen Sie eine Multiple-Choice-Frage!Bitte wählen Sie eine Multiple-Choice-Frage!',
      },
      CHECK_BOX_REPEATING_GROUP: {
        title: 'Wiederholen Sie die Gruppe für Multiple-Choice-Fragen',
        candidate: {
          explanation: 'Diese Multiple-Choice-Frage wird verwendet, um die durchzuführenden Wiederholungen zu ermitteln.',
        },
        error: 'Bitte wählen Sie eine Multiple-Choice-Frage!',
      },
    },
  },
};

export default messages;
