const TIMEOUT = 5000;

export default {
  namespaced: true,
  state: {
    messages: [],
    key: -1,
  },

  getters: {
    messages: ({ messages }) => messages,
    key: ({ key }) => key,
  },

  mutations: {
    pushMessage(state, message) {
      state.key += 1;
      state.messages = [...state.messages, { key: state.key, ...message }];
    },
    popMessage(state, key) {
      state.messages = state.messages.filter((m) => m.key !== key);
    },
  },

  actions: {
    message({ commit, getters }, message) {
      commit('pushMessage', message);

      if (!message.persistant) {
        const { key } = getters;
        setTimeout(() => {
          commit('popMessage', key);
        }, message.timeout || TIMEOUT);
      }
    },
  },
};
