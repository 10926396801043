import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/locals/messages';
import languages from '@/locals/languages';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: languages.at(0).code, // set locale
  fallbackLocale: languages.at(0).code, // set fallback locale
  messages, // set locale messages
});

export default i18n;
