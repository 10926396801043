import AxiosService from './axios/axios.idm.service';

const IdmService = {
  async login(creds) {
    return AxiosService.post('/auth/login', creds, false);
  },
  async createAdmin(body) {
    return AxiosService.post('/accounts', body);
  },
  async getAdmins() {
    return AxiosService.get('/accounts/staff');
  },
  // Not implemented yet
  async disableAdmin(id) {
    const body = {
      accountId: id,
    };
    return AxiosService.post('/accounts/staff/disable', body);
  },
};

export default IdmService;
