import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import AxiosGstService from '@/services/axios/axios.gst.service';
import i18n from '@/locals/i18n';
import languages from '@/locals/languages';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import AxiosApiService from './services/axios/axios.api.service';
import AxiosIdmService from './services/axios/axios.idm.service';
import AxiosGamService from './services/axios/axios.gam.service';

Vue.config.productionTip = false;

// Setup Axios services
AxiosApiService.init();
AxiosIdmService.init();
AxiosGamService.init();
AxiosGstService.init();

// Setup localisation
Vue.use(VueI18n);
Vue.use(VueLodash, { name: 'custom', lodash });

// Create vue app
new Vue({
  router,
  store,
  vuetify,
  i18n,
  languages,
  render: (h) => h(App),
}).$mount('#app');
