import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.module';
import flash from './flash.module';
import locale from './locale.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    flash,
    locale,
  },
});
