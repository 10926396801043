<template>
  <v-snackbar v-model="show" right text
    :color="message.type"
    :timeout="message.timeout || -1">
    {{ message.text }}
    <template v-slot:action>
      <v-btn :color="message.type" text @click="show = false">
        {{ $t('common.action.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'flash-message',
  props: {
    message: { type: Object, required: true },
  },
  data: () => ({
    show: true,
  }),
};
</script>
