import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import i18n from '@/locals/i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/NotificationsView.vue'),
    meta: {
      roles: ['admin', 'redactor'],
    },
  },
  {
    path: '/forms',
    name: 'forms',
    component: () => import('../views/FormsView.vue'),
    meta: {
      roles: ['admin', 'redactor'],
    },
  },
  {
    path: '/choiceCollections',
    name: 'choiceCollections',
    component: () => import('../views/ChoiceCollections.vue'),
    meta: {
      roles: ['admin', 'redactor'],
    },
  },
  {
    path: '/weekEvents',
    name: 'weekEvents',
    component: () => import('../views/WeekEventsView.vue'),
    meta: {
      roles: ['admin', 'redactor'],
    },
  },
  {
    path: '/weekEvents/:weekEventId',
    name: 'week-event-info',
    component: () => import('../views/WeekEventInfo.vue'),
    meta: {
      roles: ['admin', 'redactor'],
    },
    props: (route) => ({
      value: route.params.weekEvent || null,
    }),
  },
  {
    path: '/admins',
    name: 'admins',
    component: () => import('../views/AdminsView.vue'),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/forms/:formId',
    name: 'form-info',
    component: () => import('../views/FormInfo.vue'),
    meta: {
      roles: ['admin', 'redactor'],
    },
    props: (route) => ({
      formInfo: route.params.formInfo || null,
    }),
  },
  {
    path: '/choiceCollection/:collectionId',
    name: 'collection-info',
    component: () => import('../views/ChoiceCollectionInfo.vue'),
    meta: {
      roles: ['admin', 'redactor'],
    },
  },
  {
    path: '/rewardDefinitions/',
    name: 'rewardDefinitionView',
    component: () => import('../views/RewardDefinitionView.vue'),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/leaderboardDefinitions/',
    name: 'leaderboardDefinitionView',
    component: () => import('../views/LeaderboardDefinitionView.vue'),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/gameRules/',
    name: 'GameRuleView',
    component: () => import('../views/GameRuleView.vue'),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/concours',
    name: 'concours',
    component: () => import('../views/CompetitionView.vue'),
    meta: {
      roles: ['admin', 'redactor'],
    },
  },
  {
    path: '/logout',
    async beforeEnter(from, to, next) {
      await store.dispatch('auth/logout');
      next('/');
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('locale/load').then(() => {
    i18n.locale = store.getters['locale/code'];
  });
  await store.dispatch('auth/load');

  // Check roles if needed
  if (to.meta.roles) {
    const role = await store.getters['auth/role'];
    if (!to.meta.roles.includes(role)) {
      next('/');
      return;
    }
  }

  next();
});

export default router;
